header {
  z-index: calc(var(--carte-z-index)  + 100);
  flex-direction: column;
  width: 100%;
  display: flex;
  position: sticky;
  top: 0;
}

.header-lien-libre {
  font: normal normal bold 1rem / 2rem var(--primary-font-family);
  background-color: var(--theme-background);
  flex-wrap: wrap;
  align-items: center;
  column-gap: 5em;
  padding: 0 .5em 0 2.5em;
  display: flex;
}

.header-lien-libre a {
  background-color: var(--theme-background);
  color: var(--theme-on-background);
  text-decoration: none;
}

.header-lien-libre a.lien:hover {
  text-decoration: underline;
}

.header-bandeau {
  background-image: var(--url-header-background-image);
  opacity: 1;
  background-repeat: no-repeat;
  background-size: cover;
  flex-direction: column;
  justify-content: space-between;
  display: flex;
}

.header-bandeau-logos {
  align-items: flex-end;
  gap: 1em;
  padding-top: 1.875rem;
  display: flex;
}

#container.has-full-header header .header-bandeau {
  height: var(--header-bandeau-height);
}

#container:not(.has-full-header) header.tiny .header-bandeau {
  background-position: 0 calc(-1 * (var(--header-bandeau-height)  - var(--header-search-bar-height)));
}

#container:not(.has-full-header) .header-bandeau-logos {
  display: none;
}

#header-bandeau-search-bar {
  background-color: var(--theme-background-secondary-75);
  height: var(--header-search-bar-height);
  grid-template-columns: 1fr var(--content-width) 1fr;
  grid-template-areas: "logos searchbar .";
  display: grid;
}

.header-bandeau-ma-selection {
  --text-color: var(--theme-on-background);
  background: var(--theme-background) 0% 0% no-repeat padding-box;
  color: var(--text-color);
  font: normal normal bold 1rem / 1.375rem var(--primary-font-family);
  letter-spacing: 0;
  opacity: .8;
  padding: .5em 1em;
  position: absolute;
  right: 3.125rem;
}

@media (width <= 1919px) {
  .header-bandeau-ma-selection {
    padding: .3em .5em;
    line-height: 1rem;
  }
}

.header-bandeau-ma-selection svg {
  width: 1.25rem;
  height: 1.25rem;
  margin-right: .5em;
}

@media (width <= 1279px) {
  .header-bandeau-ma-selection svg {
    margin-right: 0;
  }
}

.header-bandeau-ma-selection.has-selection svg {
  color: var(--theme-secondary);
}

.header-bandeau-ma-selection.has-selection svg:first-of-type, .header-bandeau-ma-selection:not(.has-selection) svg ~ svg {
  display: none;
}

.header-bandeau-ma-selection a {
  color: var(--text-color);
  align-items: center;
  text-decoration: none;
  display: flex;
}

.header-bandeau-ma-selection-count {
  margin-left: .2rem;
}

.header-bandeau-search-bar-and-label {
  grid-area: searchbar;
  align-items: center;
  column-gap: 1em;
  display: flex;
}

.search-bar {
  font: normal normal 300 1rem / 1.375rem var(--primary-font-family);
  opacity: 1;
  flex-direction: row;
  flex-grow: 1;
  display: flex;
}

.search-bar-group-input {
  background-color: var(--theme-background-secondary);
  flex-direction: row;
  flex-grow: 1;
  align-items: center;
  padding: .5em 1em;
  display: flex;
  position: relative;
}

.search-bar-group-input > svg {
  color: var(--theme-primary);
  opacity: .7;
  width: 2.5rem;
}

@media (width <= 767px) {
  .search-bar-group-input {
    padding: 0 1em;
  }
}

.search-bar-search-input {
  font: normal normal 300 1rem / 1.375rem var(--primary-font-family);
  letter-spacing: 0;
  color: var(--theme-primary);
  border: none;
  flex-grow: 1;
  height: 100%;
  margin-left: .5rem;
}

.search-bar-search-input::placeholder {
  opacity: .7;
}

.search-bar button {
  font: normal normal bold 1rem / 1.375rem var(--primary-font-family);
  cursor: pointer;
  background-color: var(--theme-on-primary);
  color: var(--theme-primary);
  border: none;
  align-items: center;
  display: flex;
}

.search-bar button[disabled] {
  cursor: default;
}

.search-bar button[type="submit"] {
  font: normal normal bold 1rem / 1.375rem var(--primary-font-family);
  background-color: var(--theme-secondary);
  color: var(--theme-on-secondary);
  gap: .5em;
  padding: .75em 1em;
  display: flex;
}

.search-bar-recherche-carto svg {
  width: 2rem;
}

@media (width <= 1279px) {
  .search-bar-recherche-carto svg {
    width: 1.5rem;
    height: 1.5rem;
  }
}

.search-bar-input-options .search-bar-dropdown svg {
  width: 1.5rem;
  margin-right: .5em;
}

.search-bar button[type="submit"] svg {
  width: 1.25rem;
}

header .search-bar-drop-btn {
  border-left: var(--theme-bordures) solid .0625rem;
  gap: .5em;
  margin-right: 1em;
  padding: .75em 1em;
  display: flex;
}

.search-bar-drop-btn:hover, .search-bar-drop-btn:focus {
  background-color: #2c465d14;
}

.search-bar-dropdown {
  display: inline-block;
  position: relative;
}

.search-bar-dropdown-content {
  box-shadow: 0rem .1875rem .375rem var(--theme-box-shadow-color);
  z-index: 100;
  background-color: var(--theme-background-secondary);
  white-space: nowrap;
  flex-direction: column;
  min-width: 17.5rem;
  margin-top: 1em;
  padding: .5em;
  display: none;
  position: absolute;
}

.search-bar-dropdown-content button {
  font: normal normal 600 1rem / 1.375rem var(--primary-font-family);
  border-top: .0625rem solid #2c465d40;
  padding: .8em 1em;
}

.search-bar-dropdown-content button:first-child {
  border-top: none;
}

.search-bar-dropdown-content button:hover:not([disabled]) {
  background-color: #00000014;
}

.search-bar-dropdown-content button.level-2 {
  margin-left: 3em;
}

.search-bar .show {
  display: flex;
}

.search-bar-recherche-carto {
  font: normal normal bold 1rem / 1.125rem var(--primary-font-family);
  background-color: var(--theme-secondary);
  color: var(--theme-on-secondary);
  align-self: center;
  align-items: center;
  max-width: 11rem;
  height: 3rem;
  margin-right: 1rem;
  padding: 0 1rem;
  text-decoration: none;
  display: flex;
}

.search-bar-recherche-carto-label {
  margin-left: .5rem;
}

@media (width <= 767px) {
  .search-bar-recherche-carto {
    margin-left: .5rem;
    padding: .5rem;
  }
}

header .header-bandeau-tiny-logos {
  display: none;
}

header.tiny .header-bandeau-tiny-logos {
  align-items: center;
  height: 100%;
  margin-right: 1em;
  display: flex;
}

header.tiny .logo-region-simple {
  background-color: var(--theme-background-secondary);
  align-items: center;
  height: 100%;
  margin-right: 1em;
  padding: 0 1em;
  display: flex;
}

header.tiny .logo-region-simple img {
  min-height: calc(var(--header-search-bar-height) / 2);
  max-height: var(--header-search-bar-height);
  min-width: 3rem;
  max-width: 6rem;
}

header.tiny .header-bandeau-ma-selection {
  --text-color: var(--theme-on-primary);
  background: var(--theme-primary);
  color: var(--theme-on-primary);
  position: fixed;
  top: 0;
}

.header-bandeau-logos .img-link {
  min-height: 5rem;
  max-height: 7.5rem;
  display: block;
}

@media (width <= 1279px) {
  .header-bandeau-ma-selection-label, .search-bar-recherche-carto-label, .search-bar-drop-btn .search-bar-drop-btn-label, .search-bar-launch-btn-label, .search-bar-group-input > svg {
    display: none;
  }

  .header-bandeau-search-bar-and-label {
    align-items: center;
    width: 100%;
    padding: 0 1rem;
    display: flex;
  }

  header .search-bar-search-input {
    margin-left: 0;
  }

  header .search-bar-drop-btn {
    margin: 0;
    padding: .5em;
  }

  header .search-bar button[type="submit"] {
    padding: .5em;
  }
}

@media (width <= 767px) {
  .header-bandeau-ma-selection-label, .search-bar-recherche-carto-label, .search-bar-drop-btn .search-bar-drop-btn-label, .search-bar-launch-btn-label, .search-bar-group-input > svg {
    display: none;
  }

  .header-bandeau-ma-selection {
    padding: .5rem;
  }

  .header-bandeau-ma-selection svg {
    margin-right: 0;
  }

  .header-bandeau {
    background-position: 0;
    justify-content: flex-start;
    width: 100vw;
    max-width: 100vw;
  }

  #container:not(.has-full-header) .header-bandeau-logos, .header-bandeau-logos {
    height: calc(var(--header-bandeau-height)  - var(--header-search-bar-height));
    align-items: center;
    gap: .5rem;
    padding-top: 0;
    display: flex;
  }

  .header-bandeau-logos .header-bandeau-logo {
    align-items: center;
    display: flex;
  }

  .header-bandeau-logos .header-bandeau-logo-region {
    background-color: var(--theme-background-secondary);
    padding: 1rem;
  }

  .header-bandeau-logos .header-bandeau-logo-inventaire {
    padding: 1rem 0;
  }

  .header-bandeau-logos .img-link {
    object-fit: contain;
    min-height: initial;
  }

  .header-bandeau-logos .header-bandeau-logo-region .img-link {
    height: calc((var(--header-bandeau-height)  - var(--header-search-bar-height))  - 2rem);
  }

  .header-bandeau-logos .header-bandeau-logo-inventaire .img-link {
    height: calc((var(--header-bandeau-height)  - var(--header-search-bar-height))  - .5rem);
  }

  #header-bandeau-search-bar {
    height: var(--header-search-bar-height);
    align-items: flex-start;
    padding: .75rem 0;
    display: flex;
  }

  header .search-bar-dropdown {
    position: initial;
  }

  header .search-bar-dropdown-content {
    left: 0;
    right: 0;
  }

  header .search-bar-input-options .search-bar-dropdown svg {
    width: 1.25rem;
  }

  header .search-bar-input-options .search-bar-drop-btn svg {
    margin-right: 0;
  }

  header.tiny .header-bandeau-tiny-logos {
    display: none;
  }

  header.tiny .header-bandeau-ma-selection {
    --text-color: var(--theme-on-background);
    background: var(--theme-background);
  }
}

@media (width <= 400px) {
  header .search-bar-search-input {
    width: 0;
  }
}

@media print {
  header {
    display: none;
  }
}

.search-bar-recherche-experte-link {
  font-weight: bold;
  text-decoration: none;
  position: absolute;
  bottom: -1.5rem;
}

.header-link-accueils-div {
  background: var(--theme-on-primary);
  grid-area: searchbar;
  align-items: center;
  column-gap: 1em;
  min-width: 50rem;
  padding: 0 1rem;
  display: flex;
}

.link-accueils-label {
  color: var(--theme-primary);
  font: normal normal bold 1.2rem / 4rem var(--secondary-font-family);
  justify-content: center;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
  text-decoration: none;
}

.header-link-accueils-biblio-div {
  grid-area: searchbar;
  align-content: center;
  align-items: center;
  column-gap: 1em;
  min-width: 30rem;
  padding: 0 1rem;
  display: flex;
}

.link-accueils-biblio-label {
  color: var(--theme-on-secondary);
  font: normal normal bold 1rem / 1.375rem var(--primary-font-family);
  background-color: var(--theme-secondary);
  text-align: center;
  justify-content: center;
  align-items: center;
  column-gap: .625rem;
  padding: .875rem;
  text-decoration: none;
  display: flex;
}

/*# sourceMappingURL=header.60d3fd16.css.map */
