header {
  width: 100%;
  display: flex;
  flex-direction: column;
  /* On veut que le header passe par dessus les éléments cartographiques */
  z-index: calc(var(--carte-z-index) + 100);
  /* On désactive stylelint ici car il génère un faux positif pour Chromium 90 à cause du support incomplet de position: sticky sur les tableaux */
  /* stylelint-disable plugin/no-unsupported-browser-features */
  position: -webkit-sticky;
  position: sticky;
  /* stylelint-enable */
  top: 0rem;
}

.header-lien-libre {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  column-gap: 5em;
  padding: 0 0.5em 0 2.5em;
  font: normal normal bold 1rem / 2rem var(--primary-font-family);
  background-color: var(--theme-background);
}

.header-lien-libre a {
  text-decoration: none;
  background-color: var(--theme-background);
  color: var(--theme-on-background);
}

.header-lien-libre a.lien:hover {
  text-decoration: underline;
}

.header-bandeau {
  background-image: var(--url-header-background-image);
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.header-bandeau-logos {
  padding-top: 1.875rem;
  display: flex;
  align-items: flex-end;
  gap: 1em;
}

#container.has-full-header header .header-bandeau {
  height: var(--header-bandeau-height);
}

#container:not(.has-full-header) header.tiny .header-bandeau {
  background-position: 0
    calc(-1 * (var(--header-bandeau-height) - var(--header-search-bar-height)));
}
#container:not(.has-full-header) .header-bandeau-logos {
  display: none;
}

#header-bandeau-search-bar {
  background-color: var(--theme-background-secondary-75);
  display: grid;
  height: var(--header-search-bar-height);
  grid-template-columns: 1fr var(--content-width) 1fr;
  grid-template-areas: 'logos searchbar .';
}

.header-bandeau-ma-selection {
  --text-color: var(--theme-on-background);

  background: var(--theme-background) 0% 0% no-repeat padding-box;
  color: var(--text-color);
  font: normal normal bold 1rem / 1.375rem var(--primary-font-family);
  letter-spacing: 0rem;
  opacity: 0.8;
  position: absolute;
  right: 3.125rem;
  padding: 0.5em 1em;
}

@media (max-width: 1919px) {
  .header-bandeau-ma-selection {
    line-height: 1rem;
    padding: 0.3em 0.5em;
  }
}

.header-bandeau-ma-selection svg {
  margin-right: 0.5em;
  width: 1.25rem;
  height: 1.25rem;
}

@media (max-width: 1279px) {
  .header-bandeau-ma-selection svg {
    margin-right: 0;
  }
}

.header-bandeau-ma-selection.has-selection svg {
  color: var(--theme-secondary);
}

.header-bandeau-ma-selection.has-selection svg:first-of-type {
  display: none;
}

.header-bandeau-ma-selection:not(.has-selection) svg ~ svg {
  display: none;
}

.header-bandeau-ma-selection a {
  text-decoration: none;
  color: var(--text-color);
  display: flex;
  align-items: center;
}

.header-bandeau-ma-selection-count {
  margin-left: 0.2rem;
}

.header-bandeau-search-bar-and-label {
  grid-area: searchbar;
  display: flex;
  align-items: center;
  column-gap: 1em;
}

.search-bar {
  font: normal normal 300 1rem / 1.375rem var(--primary-font-family);
  display: flex;
  flex-direction: row;
  opacity: 1;
  flex-grow: 1;
}

.search-bar-group-input {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: var(--theme-background-secondary);
  padding: 0.5em 1em;
  flex-grow: 1;
  position: relative;
}

.search-bar-group-input > svg {
  color: var(--theme-primary);
  opacity: 0.7;
  width: 2.5rem;
}

@media (max-width: 767px) {
  .search-bar-group-input {
    padding: 0 1em;
  }
}

.search-bar-search-input {
  border: none;
  flex-grow: 1;
  height: 100%;
  font: normal normal 300 1rem / 1.375rem var(--primary-font-family);
  letter-spacing: 0rem;
  color: var(--theme-primary);
  margin-left: 0.5rem;
}
.search-bar-search-input::placeholder {
  opacity: 0.7;
}

.search-bar button {
  font: normal normal bold 1rem / 1.375rem var(--primary-font-family);
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  background-color: var(--theme-on-primary);
  color: var(--theme-primary);
}

.search-bar button[disabled] {
  cursor: default;
}

.search-bar button[type='submit'] {
  font: normal normal bold 1rem / 1.375rem var(--primary-font-family);
  background-color: var(--theme-secondary);
  color: var(--theme-on-secondary);
  padding: 0.75em 1em;
  display: flex;
  gap: 0.5em;
}

.search-bar-recherche-carto svg {
  width: 2rem;
}

@media (max-width: 1279px) {
  .search-bar-recherche-carto svg {
    width: 1.5rem;
    height: 1.5rem;
  }
}

.search-bar-input-options .search-bar-dropdown svg {
  margin-right: 0.5em;
  width: 1.5rem;
}

.search-bar button[type='submit'] svg {
  width: 1.25rem;
}

header .search-bar-drop-btn {
  margin-right: 1em;
  padding: 0.75em 1em;
  border-left: var(--theme-bordures) solid 0.0625rem;
  display: flex;
  gap: 0.5em;
}

.search-bar-drop-btn:hover,
.search-bar-drop-btn:focus {
  background-color: rgba(44, 70, 93, 0.08);
}

.search-bar-dropdown {
  position: relative;
  display: inline-block;
}

.search-bar-dropdown-content {
  display: none;
  flex-direction: column;
  position: absolute;
  min-width: 17.5rem;
  box-shadow: 0rem 0.1875rem 0.375rem var(--theme-box-shadow-color);
  z-index: 100;
  background-color: var(--theme-background-secondary);
  margin-top: 1em;
  padding: 0.5em;
  white-space: nowrap;
}

.search-bar-dropdown-content button {
  font: normal normal 600 1rem / 1.375rem var(--primary-font-family);
  padding: 0.8em 1em;
  border-top: rgba(44, 70, 93, 0.25) solid 0.0625rem;
}

.search-bar-dropdown-content button:first-child {
  border-top: none;
}

.search-bar-dropdown-content button:hover:not([disabled]) {
  background-color: rgba(0, 0, 0, 0.08);
}

.search-bar-dropdown-content button.level-2 {
  margin-left: 3em;
}

.search-bar .show {
  display: flex;
}

.search-bar-recherche-carto {
  font: normal normal bold 1rem / 1.125rem var(--primary-font-family);
  background-color: var(--theme-secondary);
  color: var(--theme-on-secondary);
  padding: 0 1rem;
  height: 3rem;
  max-width: 11rem;
  display: flex;
  align-items: center;
  align-self: center;
  margin-right: 1rem;
  text-decoration: none;
}

.search-bar-recherche-carto-label {
  margin-left: 0.5rem;
}

@media (max-width: 767px) {
  .search-bar-recherche-carto {
    padding: 0.5rem;
    margin-left: 0.5rem;
  }
}

header .header-bandeau-tiny-logos {
  display: none;
}

header.tiny .header-bandeau-tiny-logos {
  height: 100%;
  margin-right: 1em;
  display: flex;
  align-items: center;
}

header.tiny .logo-region-simple {
  height: 100%;
  display: flex;
  align-items: center;
  background-color: var(--theme-background-secondary);
  padding: 0 1em;
  margin-right: 1em;
}

header.tiny .logo-region-simple img {
  min-height: calc(var(--header-search-bar-height) / 2);
  min-width: 3rem;
  max-height: var(--header-search-bar-height);
  max-width: 6rem;
}

header.tiny .header-bandeau-ma-selection {
  --text-color: var(--theme-on-primary);

  position: fixed;
  top: 0;

  background: var(--theme-primary);
  color: var(--theme-on-primary);
}

.header-bandeau-logos .img-link {
  display: block;
  max-height: 7.5rem;
  min-height: 5rem;
}

@media (max-width: 1279px) {
  .header-bandeau-ma-selection-label,
  .search-bar-recherche-carto-label,
  .search-bar-drop-btn .search-bar-drop-btn-label,
  .search-bar-launch-btn-label,
  .search-bar-group-input > svg {
    display: none;
  }

  .header-bandeau-search-bar-and-label {
    display: flex;
    align-items: center;
    padding: 0 1rem;
    width: 100%;
  }

  header .search-bar-search-input {
    margin-left: 0;
  }

  header .search-bar-drop-btn {
    margin: 0;
    padding: 0.5em 0.5em;
  }

  header .search-bar button[type='submit'] {
    padding: 0.5em 0.5em;
  }
}

@media (max-width: 767px) {
  .header-bandeau-ma-selection-label,
  .search-bar-recherche-carto-label,
  .search-bar-drop-btn .search-bar-drop-btn-label,
  .search-bar-launch-btn-label,
  .search-bar-group-input > svg {
    display: none;
  }
  .header-bandeau-ma-selection {
    padding: 0.5rem;
  }
  .header-bandeau-ma-selection svg {
    margin-right: 0;
  }
  .header-bandeau {
    width: 100vw;
    max-width: 100vw;
    justify-content: flex-start;
    background-position: 0 50%;
  }
  #container:not(.has-full-header) .header-bandeau-logos,
  .header-bandeau-logos {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    height: calc(
      var(--header-bandeau-height) - var(--header-search-bar-height)
    );
    padding-top: 0;
  }
  .header-bandeau-logos .header-bandeau-logo {
    display: flex;
    align-items: center;
  }
  .header-bandeau-logos .header-bandeau-logo-region {
    padding: 1rem;
    background-color: var(--theme-background-secondary);
  }
  .header-bandeau-logos .header-bandeau-logo-inventaire {
    padding: 1rem 0;
  }
  .header-bandeau-logos .img-link {
    object-fit: contain;
    min-height: initial;
  }
  .header-bandeau-logos .header-bandeau-logo-region .img-link {
    height: calc(
      (var(--header-bandeau-height) - var(--header-search-bar-height)) - 2rem
    );
  }
  .header-bandeau-logos .header-bandeau-logo-inventaire .img-link {
    height: calc(
      (var(--header-bandeau-height) - var(--header-search-bar-height)) - 0.5rem
    );
  }

  #header-bandeau-search-bar {
    display: flex;
    align-items: flex-start;
    height: var(--header-search-bar-height);
    padding: 0.75rem 0;
  }

  header .search-bar-dropdown {
    position: initial;
  }
  header .search-bar-dropdown-content {
    left: 0;
    right: 0;
  }
  header .search-bar-input-options .search-bar-dropdown svg {
    width: 1.25rem;
  }
  header .search-bar-input-options .search-bar-drop-btn svg {
    margin-right: 0;
  }

  header.tiny .header-bandeau-tiny-logos {
    display: none;
  }

  header.tiny .header-bandeau-ma-selection {
    --text-color: var(--theme-on-background);

    background: var(--theme-background);
  }
}

@media (max-width: 400px) {
  header .search-bar-search-input {
    /* Workaround : la barre de recherche ne veut pas se redimmensionner à partir de ce point de cassure et casse l'affichage sur FF et Chrome... */
    width: 0rem;
  }
}

@media print {
  header {
    display: none;
  }
}

.search-bar-recherche-experte-link {
  position: absolute;
  bottom: -1.5rem;
  text-decoration: none;
  font-weight: bold;
}

.header-link-accueils-div {
  grid-area: searchbar;
  display: flex;
  align-items: center;
  column-gap: 1em;
  background: var(--theme-on-primary);
  padding: 0 1rem;
  min-width: 50rem;
}

.link-accueils-label {
  text-decoration: none;
  color: var(--theme-primary);
  justify-content: center;
  align-items: center;
  font: normal normal bold 1.2rem/4rem var(--secondary-font-family);
  padding-left: 1rem;
  padding-right: 1rem;
}

.header-link-accueils-biblio-div {
  grid-area: searchbar;
  align-items: center;
  column-gap: 1em;
  min-width: 30rem;
  padding: 0 1rem;
  display: flex;
  align-content: center;
}

.link-accueils-biblio-label {
  color: var(--theme-on-secondary);
  font: normal normal bold 1rem/1.375rem var(--primary-font-family);
  justify-content: center;
  text-decoration: none;
  background-color: var(--theme-secondary);
  align-items: center;
  column-gap: 0.625rem;
  padding: 0.875rem;
  display: flex;
  text-align: center;
}
